import React from "react";
import { useSelector } from "react-redux";
import Toast from "@mercell/toast-react";
import { useTranslation } from "react-i18next";
import { selectCreateSubscriptionFailed } from "../../redux/CheckoutSlice";

const SubscriptionCreationFailedToast = () => {
  const { t } = useTranslation();

  const subscriptionCreationFailed = useSelector(
    selectCreateSubscriptionFailed
  );

  return subscriptionCreationFailed ? (
    <Toast isInteractive>{t("checkout.subscription_created_failed")}</Toast>
  ) : (
    <></>
  );
};

export default SubscriptionCreationFailedToast;
