import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { AppLayout } from "@mercell/layout-react";
import { useDispatch } from "react-redux";
import ContentWrapper from "./components/ContentWrapper";
import NotFound from "./components/NotFound";
import Checkout from "./components/checkout/Checkout";
import WelcomePage from "./components/WelcomePage";
import AuthWrapper from "./components/AuthWrapper";
import MyPage from "./components/mypage/MyPage";
import { clearRedirectTo } from "./redux/RedirectSlice";
import "./App.css";
import PleaseVerifyEmail from "./components/auth/PleaseVerifyEmail";
import EmailVerified from "./components/auth/EmailVerified";
import { USER_VERIFY_EMAIL_URL } from "./config/constants";

function App() {
  const location = useLocation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(clearRedirectTo());
  }, [dispatch, location]);
  return (
    <AppLayout className="bg-white">
      <AuthWrapper>
        <Switch>
          <Route exact path={["/", "/logout"]}>
            <ContentWrapper>
              <WelcomePage />
            </ContentWrapper>
          </Route>
          <Route exact path="/mypage">
            <ContentWrapper>
              <MyPage />
            </ContentWrapper>
          </Route>
          <Route exact path="/checkout">
            <ContentWrapper>
              <div className="mt-24 w-11/12 md:w-10/12">
                <Checkout />
              </div>
            </ContentWrapper>
          </Route>
          <Route exact path={USER_VERIFY_EMAIL_URL}>
            <ContentWrapper>
              <PleaseVerifyEmail />
            </ContentWrapper>
          </Route>
          <Route exact path="/email_verified">
            <ContentWrapper>
              <EmailVerified />
            </ContentWrapper>
          </Route>
          <Route>
            <ContentWrapper>
              <NotFound />
            </ContentWrapper>
          </Route>
        </Switch>
      </AuthWrapper>
    </AppLayout>
  );
}

export default App;
