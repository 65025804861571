import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { RouteComponentProps } from "react-router-dom";
import { RootState } from "./store";

export interface RedirectState {
  redirectTo: string | null;
}

const initialState: RedirectState = {
  redirectTo: null,
};

export function redirectInternally(
  url: string,
  history: RouteComponentProps["history"]
) {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    if (state.redirect.redirectTo) {
      console.warn(
        `Redirect is already progress to ${state.redirect.redirectTo}`
      );
    } else {
      dispatch(setRedirectTo(url));
      history.push(url);
    }
  };
}

export function redirectExternally(url: string) {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    if (state.redirect.redirectTo) {
      console.warn(
        `Redirect is already progress to ${state.redirect.redirectTo}`
      );
    } else {
      dispatch(setRedirectTo(url));
      window.location.assign(url);
    }
  };
}

export const redirectSlice = createSlice({
  name: "redirect",
  initialState,
  reducers: {
    setRedirectTo(state, action: PayloadAction<string>) {
      state.redirectTo = action.payload;
    },
    clearRedirectTo(state) {
      state.redirectTo = null;
    },
  },
});

export const { setRedirectTo, clearRedirectTo } = redirectSlice.actions;

export const selectIsRedirectInProgress = (state: RootState) =>
  state.redirect.redirectTo;

export default redirectSlice.reducer;
