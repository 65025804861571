import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { Invoice } from "../domain/Invoice";

export interface MyPageState {
  invoices: Invoice[] | null;
}

const initialState: MyPageState = {
  invoices: null,
};

export const myPageSlice = createSlice({
  name: "myPage",
  initialState,
  reducers: {
    setInvoices(state, action: PayloadAction<Invoice[]>) {
      state.invoices = action.payload;
    },
  },
  extraReducers: {},
});

export const { setInvoices } = myPageSlice.actions;

export const selectInvoices = (state: RootState) => state.myPage.invoices;

export default myPageSlice.reducer;
