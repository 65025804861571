import { ENGLAND_LOCALE } from "../domain/Country";

export const datePlusDays = (date: Date, days: number): Date => {
  // Need to instantiate a new Date to ensure immutability
  const temp = new Date(date);
  temp.setDate(temp.getDate() + days);
  return temp;
};

export const datePlusMonths = (date: Date, months: number): Date => {
  const temp = new Date(date);
  temp.setUTCMonth(temp.getUTCMonth() + months);
  return temp;
};

export const formatInstant = (
  instant: string,
  locale: string
): string | null => {
  const date = new Date(instant);
  if (date.toString() === "Invalid Date") {
    console.error("Couldn't parse instant...");
    return null;
  }
  return formatDate(date, locale);
};

export const formatDate = (date: Date, locale: string): string => {
  try {
    return Intl.DateTimeFormat(locale).format(date);
  } catch (e) {
    return Intl.DateTimeFormat(ENGLAND_LOCALE.locale).format(date);
  }
};
