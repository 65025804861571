import { useTranslation } from "react-i18next";
import React from "react";
import { CheckmarkFilled20 } from "@carbon/icons-react";
import Card from "@mercell/card-react";
import { useSelector } from "react-redux";
import { selectPortfolio } from "../../redux/CheckoutSlice";
import { portfolioDummyData } from "../../domain/fixtures";

const SelectedPlan = () => {
  const { t } = useTranslation();
  const portfolio = useSelector(selectPortfolio) || portfolioDummyData[0];

  return (
    <Card>
      <div className="flex flex-row items-center">
        <CheckmarkFilled20 color="#006B51" />
        <h3 className="px-4">
          {t("checkout.selected_plan")} {portfolio.name}
        </h3>
      </div>
    </Card>
  );
};

export default SelectedPlan;
