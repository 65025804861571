import { Store } from "../redux/store";
import { startLaunchDarkly } from "../api/LaunchDarklyClient";
import { LAUNCH_DARKLY_ENV_KEY } from "./constants";
import { Flags } from "../domain/flags";

export async function startStates(store: Store, flags: Flags[]) {
  if (!LAUNCH_DARKLY_ENV_KEY) {
    throw Error("LAUNCH_DARKLY_ENV_KEY not set");
  }

  const stopLaunchDarkly = startLaunchDarkly(
    LAUNCH_DARKLY_ENV_KEY,
    store,
    flags
  );
  return () => {
    stopLaunchDarkly();
  };
}
