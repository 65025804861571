import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  defaultFlagData,
  Flags,
  FlagsChangeData,
  FlagsData,
} from "../domain/flags";
import { RootState } from "./store";

export interface FlagsState {
  data: FlagsData;
}

const initialState: FlagsState = {
  data: defaultFlagData,
};

export const flagsSlice = createSlice({
  name: "flags",
  initialState,
  reducers: {
    initFlags(state, action: PayloadAction<FlagsData>) {
      state.data = action.payload;
    },
    changeFlags(state, { payload }: PayloadAction<FlagsChangeData>) {
      Object.entries(payload).forEach(([key, value]) => {
        if (value !== undefined)
          state.data[key as keyof typeof Flags] = value.current;
      });
    },
  },
});

export const { initFlags, changeFlags } = flagsSlice.actions;

export const selectFlag = (flag: Flags) => (state: RootState) =>
  state.flags.data[flag];

export default flagsSlice.reducer;
