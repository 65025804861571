import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import useSWR from "swr";
import { useTranslation } from "react-i18next";
import {
  getActiveSubscriptionsByPortfolioId,
  selectCreateSubscriptionSucceeded,
  selectPortfolio,
  setPortfolio,
} from "../../redux/CheckoutSlice";
import { PORTFOLIO_GET_ENDPOINT } from "../../config/constants";
import InputBlock from "./InputBlock";
import Summary from "./Summary";
import { portfolioDummyData } from "../../domain/fixtures";
import SubscriptionCreationFailedToast from "./SubscriptionCreationFailedToast";
import { selectIsLoggedIn } from "../../redux/UserSlice";
import Receipt from "./Receipt";
import InformationForm from "./InformationForm";
import "./Checkout.css";

const Checkout = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const chosenPortfolio = useSelector(selectPortfolio);
  const isAuthenticated = useSelector(selectIsLoggedIn);

  const { data, error } = useSWR(PORTFOLIO_GET_ENDPOINT);
  const isLoadingPortfolio = !data && !error;
  const portfolio = chosenPortfolio || portfolioDummyData[0];

  useEffect(() => {
    if (data && data.length > 0) {
      dispatch(setPortfolio(data[0]));
      dispatch(getActiveSubscriptionsByPortfolioId(portfolio.id));
    }
  }, [dispatch, data, portfolio.id]);
  const checkoutComponent = (
    <main className="flex flex-wrap-reverse md:flex-row justify-between md:mt-20">
      <div className="w-full lg:w-7/12">
        <InputBlock />
      </div>
      <div className="w-full lg:w-5/12 pb-4 lg:pl-3">
        <Summary isLoading={isLoadingPortfolio} portfolio={portfolio} />
        <div className="pb-3" />
        <InformationForm />
      </div>
      <h1 className="mb-4">{t("checkout.checkout_header")}</h1>
    </main>
  );
  const createSubscriptionSucceeded = useSelector(
    selectCreateSubscriptionSucceeded
  );
  const checkoutOrReceipt = createSubscriptionSucceeded ? (
    <Receipt portfolio={portfolio} />
  ) : (
    checkoutComponent
  );
  return isAuthenticated ? (
    <div className="flex flex-grow flex-col">
      {error ? (
        <h1 className="text-center">
          {t("checkout.portfolio.retrieve_error")}
        </h1>
      ) : (
        checkoutOrReceipt
      )}
      <SubscriptionCreationFailedToast />
    </div>
  ) : (
    <></>
  );
};

export default Checkout;
