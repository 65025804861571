import { Store } from "redux";
import { RootState } from "../redux/store";

export function observeStoreWithInitState<T>(
  store: Store,
  select: (state: RootState) => T,
  onChange: (newValue: T) => void,
  initState: T
) {
  let currentState = initState;
  function handleChange() {
    const nextState = select(store.getState());
    if (nextState !== currentState) {
      currentState = nextState;
      onChange(currentState);
    }
  }

  const unsubscribe = store.subscribe(handleChange);
  handleChange();
  return unsubscribe;
}

export function observeStore<T>(
  store: Store,
  select: (state: RootState) => T,
  onChange: (newValue: T) => void
) {
  const initState = select(store.getState());
  return observeStoreWithInitState(store, select, onChange, initState);
}
