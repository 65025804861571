import React from "react";
import { Button } from "@mercell/button-react";
import { ChevronDown24, UserAvatar24 } from "@carbon/icons-react";
import { DropdownMenu } from "@mercell/dropdown-menu-react";
import { Topbar } from "@mercell/topbar-react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Toast from "@mercell/toast-react";
import { useTranslation } from "react-i18next";

import {
  logout,
  selectLocale,
  selectLoginFailed,
  selectLogoutFailed,
  selectUser,
  setLocale,
} from "../redux/UserSlice";
import "./Header.css";

import { prepareLoginWithRedirectUrl } from "../api/Auth";
import { Locale, LOCALES } from "../domain/Country";
import LanguageSelector from "./language/LanguageSelector";
import { redirectExternally } from "../redux/RedirectSlice";

function Header() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectUser);
  const showLoginError = useSelector(selectLoginFailed);

  const [show, setShow] = React.useState(false);
  const hideMenu = () => setShow(false);
  const toggleShow = () => setShow(!show);

  const onLogoClick = () => history.push("/");
  const onLogin = async () => {
    const url = prepareLoginWithRedirectUrl(true, window.location.pathname);
    dispatch(redirectExternally(url));
  };
  const showLogoutError = useSelector(selectLogoutFailed);

  const onLogoutClick = async (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    dispatch(logout());
  };

  const { t, i18n } = useTranslation();
  const currentLocale = useSelector(selectLocale);
  const changeLanguage = (locale: Locale) => {
    dispatch(setLocale(locale));
    i18n?.changeLanguage(locale.locale);
  };

  return (
    <>
      {showLoginError && (
        <Toast isInteractive>{t("header.login_failed")}</Toast>
      )}
      {showLogoutError && (
        <Toast isInteractive>{t("header.logout_failed")}</Toast>
      )}
      <Topbar className="fixed p-4" onClick={onLogoClick}>
        <div className="flex flex-row">
          {!user && (
            <Button scheme="primary" onClick={onLogin}>
              Login
            </Button>
          )}
          {user && (
            <Button
              scheme="tertiary"
              aria-controls="profile-selector"
              className="space-x-2 flex items-center"
              onClick={toggleShow}
            >
              <UserAvatar24 />
              <span className="sm:hidden">Account</span>
              <span className="hidden sm:block">{user?.name}</span>
              <ChevronDown24 className="hidden sm:block" />
            </Button>
          )}

          <div className="relative flex">
            {show && (
              <DropdownMenu
                id="profile-selector"
                onClose={hideMenu}
                style={{ zIndex: 51, bottom: 0 }}
                className="bg-base transform sm:translate-y-full"
              >
                <li>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href="#"
                    onClick={onLogoutClick}
                    className="interactive p-4 flex items-center"
                  >
                    {t("header.logout")}
                  </a>
                </li>
              </DropdownMenu>
            )}
          </div>
          <LanguageSelector
            id="language-selector"
            label={t("header.language_selector")}
            locales={LOCALES}
            locale={currentLocale}
            onClick={changeLanguage}
          />
        </div>
      </Topbar>
    </>
  );
}

export default Header;
