import * as z from "zod";

export enum RequestStatus {
  NOT_INITIATED = "NOT_INITIATED",
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

export const ResponseErrorSchema = z.object({
  error: z.string(),
});
export type ResponseError = z.infer<typeof ResponseErrorSchema>;

export function validate<T extends z.ZodRawShape>(
  data: Record<string, unknown>,
  validator: z.ZodObject<T>
) {
  const result = validator.safeParse(data);
  if (result.success) {
    return result.data;
  }
  throw Error(result.error.message);
}
