import React from "react";
import Header from "./Header";
import Footer from "./Footer";

interface ContentWrapperProps {
  children: React.ReactNode;
}

const ContentWrapper = ({ children }: ContentWrapperProps) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};
export default ContentWrapper;
