import React from "react";

const Footer = () => {
  return (
    <footer className="flex w-full justify-between mb-4 mt-6 px-4 sm:px-24">
      <div className="flex items-center flex-wrap space-x-4">
        <span className="inline-block px-2 text-center border-solid border-2 border-contrast font-roboto-condensed font-bold text-heading-3">
          Beta
        </span>
        <a
          className="link-black"
          href="https://mercell.atlassian.net/servicedesk/customer/portal/61?src=1983319036"
          target="_blank"
          rel="noreferrer"
        >
          Give feedback
        </a>
      </div>
      <h3>Mercell Group {new Date().getFullYear()}</h3>
    </footer>
  );
};
export default Footer;
