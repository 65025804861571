import { configureStore } from "@reduxjs/toolkit";
import flagsReducer from "./LauchDarklySlice";
import userReducer from "./UserSlice";
import CheckoutReducer from "./CheckoutSlice";
import RedirectReducer from "./RedirectSlice";
import MySubscriptionReducer from "./subscription-app/MySubscriptionsSlice";
import MyPageReducer from "./MyPageSlice";

export const getStore = () => {
  return configureStore({
    reducer: {
      flags: flagsReducer,
      user: userReducer,
      checkout: CheckoutReducer,
      redirect: RedirectReducer,
      mySubscriptions: MySubscriptionReducer,
      myPage: MyPageReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false, // we need to pass location history that is not serializable to redirect slice,
      }),
  });
};
export const store = getStore();
export type Store = typeof store;

export const selectState = (state: RootState) => state;

export type RootState = ReturnType<typeof store.getState>;
