import React, { useMemo } from "react";
import { useSelector, useStore } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "@mercell/button-react";
import { selectIsTrial } from "../../redux/CheckoutSlice";
import { CheckoutHandler } from "../../redux/CheckoutHandler";
import { selectState } from "../../redux/store";

const CheckoutButton = () => {
  const { t } = useTranslation();
  const store = useStore();
  const state = useSelector(selectState);
  const isTrial = useSelector(selectIsTrial);
  const isDisabled = useMemo(
    () => !CheckoutHandler.canCreateSubscription(state),
    [state]
  );
  return (
    <Button
      id="checkout-button"
      disabled={isDisabled}
      scheme="primary"
      onClick={() => CheckoutHandler.createSubscription(store)}
      className="justify-center"
    >
      {isTrial
        ? t("checkout.start_trial_period")
        : t("checkout.create_subscription")}
    </Button>
  );
};

export default CheckoutButton;
