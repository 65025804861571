import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import { SWRConfig } from "swr";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { store } from "./redux/store";
import App from "./App";
import { appContext, appContextValue } from "./config/context";
import { fetcher } from "./api/fetcher";
import { initI18n } from "./i18n/init";
import { initApp } from "./init";

initApp(store);
ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={initI18n()}>
      <Provider store={store}>
        <appContext.Provider value={appContextValue}>
          <SWRConfig value={{ fetcher }}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </SWRConfig>
        </appContext.Provider>
      </Provider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
