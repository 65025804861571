import React from "react";
import Checkbox from "@mercell/checkbox-react";
import { useTranslation } from "react-i18next";
import { Invoice } from "../../../domain/Invoice";
import { formatPrice } from "../../../utils/numbers";

interface InvoiceTableRowProps {
  invoice: Invoice;
}

const InvoiceTableRow = ({ invoice }: InvoiceTableRowProps) => {
  const { i18n } = useTranslation();
  const dueDate = new Date(invoice.dueDate);
  const subPeriodStartedAt = new Date(invoice.subPeriodStartedAt);
  return (
    <tr>
      <td className="w-1/12 py-2">
        <Checkbox
          id={`invoice_check_${invoice.uuid}`}
          style={{ display: "none" }}
        />
      </td>
      <td className="w-4/12 py-2">
        Invoice-{subPeriodStartedAt.getUTCDate()}.
        {subPeriodStartedAt.getUTCMonth()}.{subPeriodStartedAt.getUTCFullYear()}
      </td>
      <td className="w-2/12 py-2">
        {dueDate.getUTCDate()}.{dueDate.getUTCMonth()}.
        {dueDate.getUTCFullYear()}
      </td>
      <td className="w-2/12 py-2">{invoice.isPaid ? "Paid" : "Not paid"}</td>
      <td className="w-2/12 py-2">
        {formatPrice(invoice.price, invoice.currency, i18n.language)}
      </td>
      <td className="w-1/12 py-2" style={{ display: "none" }}>
        ...
      </td>
    </tr>
  );
};

export default InvoiceTableRow;
