import React from "react";
import { useTranslation } from "react-i18next";

const WelcomePage = () => {
  const { t } = useTranslation();
  return (
    <main className="mt-24 w-full flex flex-grow items-center justify-center">
      <div className="shadow-lg p-10">
        <h3>{t("welcome.welcome_to_online_sales")}</h3>
        <p>
          {t("welcome.description")} <br />
          {t("welcome.work_in_progress")} <br />
          <br />
          {t("welcome.sign_up_or_log_in")}
        </p>
      </div>
    </main>
  );
};

export default WelcomePage;
