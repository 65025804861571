import * as z from "zod";
import { validate } from "../redux/common";

export enum CountryCode {
  NORWAY = "NO",
  DENMARK = "DK",
  ENGLAND = "GB",
  SWEDEN = "SV",
  USA = "US",
}
export const LocaleSchema = z.object({
  country: z.nativeEnum(CountryCode),
  languageName: z.string(),
  locale: z.string(),
  phonePrefix: z.string(),
});

export type Locale = z.infer<typeof LocaleSchema>;

export const NORWAY_LOCALE: Locale = {
  country: CountryCode.NORWAY,
  locale: "nb-NO",
  languageName: "Norsk",
  phonePrefix: "+ 47",
};

export const ENGLAND_LOCALE: Locale = {
  country: CountryCode.ENGLAND,
  locale: "en-GB",
  languageName: "English",
  phonePrefix: "+ 44",
};

export const USA_LOCALE: Locale = {
  country: CountryCode.USA,
  locale: "en-US",
  languageName: "English",
  phonePrefix: "+ 1",
};

export const DENMARK_LOCALE: Locale = {
  country: CountryCode.DENMARK,
  locale: "da-DK",
  languageName: "Dansk",
  phonePrefix: "+ 45",
};

export const SWEDEN_LOCALE: Locale = {
  country: CountryCode.SWEDEN,
  locale: "sv-SE",
  languageName: "Svenska",
  phonePrefix: "+ 46",
};

export const LOCALES: Locale[] = [
  NORWAY_LOCALE,
  DENMARK_LOCALE,
  ENGLAND_LOCALE,
  SWEDEN_LOCALE,
];

const OLD_LOCAL_KEYS = ["user_locale"];
const LOCAL_KEY = "user_locale.v2";

export const getLocaleFromStorage = () => {
  // remove old version of locale schema
  OLD_LOCAL_KEYS.forEach((k) => localStorage.removeItem(k));
  return localStorage.getItem(LOCAL_KEY);
};

export const getUserInitialLocale = (): Locale => {
  // get from local storage
  const stringData = getLocaleFromStorage();
  if (stringData) {
    const data = JSON.parse(stringData);
    return validate(data, LocaleSchema);
  }
  // fall back to window locale
  const userLocale = window.navigator.language;
  // if window locale is unknown, fall back to default locale
  return LOCALES.find((l) => l.locale === userLocale) || NORWAY_LOCALE;
};
export const removeLocaleFromStorage = () => localStorage.removeItem(LOCAL_KEY);
export const setLocaleToStorage = (locale: Locale) =>
  localStorage.setItem(LOCAL_KEY, JSON.stringify(locale));

export const getLocaleByCountry = (country: CountryCode) => {
  return LOCALES.find((l) => l.country === country);
};
