import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import mailOpened from "../../assets/images/mail_opened.png";
import { selectUser } from "../../redux/UserSlice";

function PleaseVerifyEmail() {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  return (
    <main className="mt-24 w-full flex flex-grow items-center justify-center">
      <div className="flex flex-col sm:flex-row space-x-6 mt-16 items-center justify-center w-full lg:w-1/2 mx-6">
        <div className="flex sm:w-1/2 h-fit-content lg:justify-end">
          <img
            src={mailOpened}
            alt={t("auth.please_verify_email.image_alt")}
            height={174}
            width={235}
          />
        </div>
        <div className="sm:w-1/2 mt-2">
          <h1 className="flex items-center">
            {t("auth.please_verify_email.title")}
          </h1>
          <div>{t("auth.please_verify_email.email_sent")}</div>
          <b>{user?.email}</b>
          <div>{t("auth.please_verify_email.instruction")}</div>
        </div>
      </div>
    </main>
  );
}

export default PleaseVerifyEmail;
