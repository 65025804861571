import { startStates } from "./config/states";
import { initAxios } from "./api";
import { Store } from "./redux/store";
import { Flags } from "./domain/flags";

export const initApp = async (store: Store) => {
  initAxios(store);
  const stopState = await startStates(store, [
    Flags.HIDE_INVOICE_DUE_DATE,
    Flags.ADMIN_ENABLE_MULTIPLE_SUBSCRIPTIONS,
  ]);
  return () => {
    stopState();
  };
};
