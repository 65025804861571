import React, { useState, useEffect, LegacyRef } from "react";
import useLoadingSkeleton from "@mercell/use-loading-react-hook";
import Checkbox from "@mercell/checkbox-react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import InvoiceTableRow from "./InvoiceTableRow";
import { selectInvoices, setInvoices } from "../../../redux/MyPageSlice";
import { invoiceDummyData } from "../../../domain/fixtures";
import { getMyInvoices } from "../../../api/OseApi";

const InvoiceTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const chosenInvoices = useSelector(selectInvoices);

  const invoices = chosenInvoices || invoiceDummyData;
  const invoicesFound: boolean = chosenInvoices
    ? chosenInvoices.length > 0
    : false;
  const [error, setError] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const ref = useLoadingSkeleton(invoicesFound)
    .ref as unknown as LegacyRef<HTMLTableSectionElement>;

  const invoicesNotFound = () => {
    setNotFound(true);
  };

  const foundError = () => {
    setError(true);
  };

  useEffect(() => {
    getMyInvoices(
      (i) => {
        dispatch(setInvoices(i));
      },
      invoicesNotFound,
      foundError
    );
  }, [dispatch]);

  const invoicesOrNone = invoicesFound ? (
    invoices.map((invoice) => {
      return <InvoiceTableRow key={invoice.uuid} invoice={invoice} />;
    })
  ) : (
    <tr>
      <td />
      <td className="w-full">No invoices found</td>
    </tr>
  );

  return (
    <div>
      <table className="table-fixed w-8/12 mt-12 text-left divide-y space-y-6">
        <thead className="mb-4">
          <tr>
            <th className="w-1/12">
              <Checkbox id="invoice_check_all" style={{ display: "none" }} />
            </th>
            <th className="w-4/12">Invoice</th>
            <th className="w-2/12">Due date</th>
            <th className="w-2/12">Status</th>
            <th className="w-2/12">Price</th>
            <th className="w-1/12" style={{ display: "none" }}>
              ...
            </th>
          </tr>
        </thead>
        <tbody className="divide-y" ref={ref}>
          {invoicesOrNone}
        </tbody>
      </table>
      {notFound && (
        <div className="text-invalid ml-4 mt-2">
          {t("my_page.invoice_history.invoices_not_found")}
        </div>
      )}
      {error && (
        <div className="text-invalid ml-4 mt-2">
          {t("my_page.invoice_history.error")}
        </div>
      )}
    </div>
  );
};

export default InvoiceTable;
