import axios from "axios";
import { fetcher } from "./fetcher";
import {
  ORG_SEARCH_ORGNR_GET_ENDPOINT,
  ORG_SEARCH_NAME_GET_ENDPOINT,
  SUBSCRIPTION_ENDPOINT,
  INVOICE_ENDPOINT,
} from "../config/constants";
import { CountryCode } from "../domain/Country";
import { Organisation } from "../domain/organisation";
import { Invoice } from "../domain/Invoice";

export const getMyPurchases = () => {
  fetcher(SUBSCRIPTION_ENDPOINT);
};

export const searchForOrganisationNumber = (
  orgnr: string,
  country: CountryCode | null,
  callback: (a: Organisation) => void,
  notFound: () => void
) => {
  if (country != null) {
    axios
      .get<Organisation>(`${ORG_SEARCH_ORGNR_GET_ENDPOINT}/${country}/${orgnr}`)
      .then((res) => {
        if (res.status === 200) {
          callback(res.data);
        }
      })
      .catch(() => {
        notFound();
      });
  }
};

export const searchForOrganisationName = (
  name: string,
  country: CountryCode | null,
  callback: (a: Organisation[]) => void,
  notfound: () => void = () => {}
) => {
  if (country != null && name.length > 0) {
    axios
      .get<Organisation[]>(`${ORG_SEARCH_NAME_GET_ENDPOINT}/${country}/${name}`)
      .then((res) => {
        if (res.status === 200) {
          callback(res.data);
        } else if (res.status === 404) {
          notfound();
        }
      });
  }
};

export const getMyInvoices = (
  callback: (a: Invoice[]) => void,
  notfound: () => void,
  error: () => void
) => {
  axios.get<Invoice[]>(INVOICE_ENDPOINT).then((res) => {
    if (res.status === 200) {
      callback(res.data);
    } else if (res.status === 404) {
      notfound();
    } else {
      error();
    }
  });
};
