/**
 * Internal function to validate the number
 */
import { CountryCode } from "./Country";

export const validatePhoneNumber = (phoneNumber: string): boolean => {
  // very loose validation
  return /^\+{0,1}[0-9]{1,}[0-9 -]{3,}$/.test(phoneNumber.trim());
};
export const validatePostalCode = (
  postalCode: string,
  country: CountryCode | null
): boolean => {
  switch (country) {
    case CountryCode.NORWAY: {
      return /^\d{4}$/.test(postalCode.trim());
    }
    case CountryCode.DENMARK: {
      return /^\d{4}$/.test(postalCode.trim());
    }
    case CountryCode.ENGLAND: {
      return /^[a-zA-Z]{2}[0-9 ]{4,8}$/.test(postalCode.trim());
    }
    default: {
      // TODO: Should log to server side that validation for the given country is missing.
      return true;
    }
  }
};

export const validateEmail = (email: string): boolean => {
  return /^\S+@\S+.\S+$/.test(email) || email.trim().length === 0;
};

export const validateOrgNumber = (
  number: string,
  country: CountryCode | null
): boolean => {
  switch (country) {
    case CountryCode.NORWAY: {
      return validateNorwegianOrgNumber(number);
    }
    case CountryCode.DENMARK: {
      return validateDanishOrgNumber(number);
    }
    case CountryCode.ENGLAND: {
      return validateGBVatNumber(number);
    }
    default:
      // TODO: Should log to server side that validation for the given country is missing.
      return true;
  }
};

// validate org number according to https://www.brreg.no/om-oss/oppgavene-vare/alle-registrene-vare/om-enhetsregisteret/organisasjonsnummeret/
function validateNorwegianOrgNumber(rawNumber: string): boolean {
  if (rawNumber == null || !/^\d{9}$/.test(rawNumber.trim())) return false;
  const numbers = rawNumber.trim();
  const controlNumbers = [3, 2, 7, 6, 5, 4, 3, 2];
  const sum = numbers
    .substr(0, 8)
    .split("")
    .map((v, i) => parseInt(v, 10) * controlNumbers[i])
    .reduce((acc, value) => acc + value);
  let controlResult = 11 - (sum % 11);
  switch (controlResult) {
    case 11:
      controlResult = 0;
      break;
    case 10:
      // 10 is invalid by the rule
      return false;
  }
  const controlDigit = parseInt(numbers.charAt(8), 10);
  return controlResult === controlDigit;
}

// validate danish org number according to https://wiki.scn.sap.com/wiki/display/CRM/Denmark
function validateDanishOrgNumber(rawNumber: string): boolean {
  if (
    rawNumber == null ||
    rawNumber.trim().length !== 8 ||
    !/^\d*$/.test(rawNumber.trim())
  )
    return false;
  const numbers = rawNumber.trim();
  const controlNumbers = [2, 7, 6, 5, 4, 3, 2, 1];
  const sum = numbers
    .split("")
    .map((v, i) => parseInt(v, 10) * controlNumbers[i])
    .reduce((acc, value) => acc + value);

  return sum % 11 === 0;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function validateGBVatNumber(vatnumber: string): boolean {
  return true;
}
