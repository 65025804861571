import React, { LegacyRef } from "react";
import useLoadingSkeleton from "@mercell/use-loading-react-hook";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { monthsInPeriod, Portfolio } from "../../domain/Portfolio";
import {
  getPriceForBillingPeriod,
  selectBillingPeriod,
} from "../../redux/CheckoutSlice";
import {
  datePlusDays,
  datePlusMonths,
  formatDate,
} from "../../utils/dateFormatter";
import { getPeriodPriceSuffix } from "../../utils/strings";
import { formatPrice } from "../../utils/numbers";
import { selectFlag } from "../../redux/LauchDarklySlice";
import { Flags } from "../../domain/flags";

interface SummaryProps {
  portfolio: Portfolio;
  isLoading?: boolean;
}

const Summary = ({ portfolio, isLoading = false }: SummaryProps) => {
  const ref = useLoadingSkeleton(isLoading)
    .ref as unknown as LegacyRef<HTMLDivElement>;
  const { t, i18n } = useTranslation();

  const chosenBillingPeriod = useSelector(selectBillingPeriod);
  const HIDE_INVOICE_DUE_DATE_FLAG = useSelector(
    selectFlag(Flags.HIDE_INVOICE_DUE_DATE)
  );

  const periodStart = new Date();
  const periodEnd = datePlusMonths(
    periodStart,
    monthsInPeriod(chosenBillingPeriod)
  );
  const subscriptionPeriod = `${formatDate(
    periodStart,
    i18n.language
  )} - ${formatDate(periodEnd, i18n.language)}`;
  const dueDate = datePlusDays(periodStart, portfolio.trialDays);
  const price = formatPrice(
    getPriceForBillingPeriod(portfolio, chosenBillingPeriod),
    portfolio.currency,
    i18n.language
  );

  const periodText = t(getPeriodPriceSuffix(chosenBillingPeriod));

  return (
    <div className="summary-card" ref={ref}>
      <h2 className="mx-6 mt-2">{t("checkout.summary.title")}</h2>
      <div className="mt-2 mx-6">
        <div>
          {t("checkout.summary.subscription_period")}: {subscriptionPeriod}
        </div>
        {!HIDE_INVOICE_DUE_DATE_FLAG && (
          <div>
            {t("checkout.summary.due_date")}:{" "}
            {formatDate(dueDate, i18n.language)}
          </div>
        )}
      </div>
      <div className="flex flex-row mt-8 py-4">
        <div className="flex flex-row w-full justify-between mx-6">
          <div className="w-7/12">
            <div className="title mr-1">{t("checkout.product")}</div>
            {portfolio.name},{" "}
          </div>
          <div className="w-5/12">
            <div className="title text-right"> {t("price")}</div>
            <div className="break-normal text-right">
              {price}/{periodText}
            </div>
          </div>
        </div>
      </div>

      <div className="summary-total mt-14">
        <div className="flex flex-row py-2.5">
          <div className="flex flex-row w-full justify-between mx-6">
            <div className="title w-7/12">
              {t("checkout.summary.total_amount")}
            </div>
            <div className="w-5/12 title text-right">
              {price}/{periodText}
            </div>
          </div>
        </div>
      </div>

      <p className="mx-6 small-text mt-3 pb-1">
        {t("checkout.summary.three_months_renewal_warning")}
      </p>
    </div>
  );
};

Summary.defaultProps = {
  isLoading: false,
};

export default Summary;
