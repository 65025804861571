import { Currency, Portfolio } from "./Portfolio";
import { Invoice } from "./Invoice";

export const portfolioDummyData: Array<Portfolio> = [
  {
    id: 1,
    name: "Mercell Tender Finder All Norway",
    priceYear: 24000,
    priceMonth: 2500,
    currency: Currency.NOK,
    trialDays: 90,
    bundles: [
      {
        id: 1,
        name: "Bundle 1",
        actions: [
          {
            domainId: 1,
            domain: "Domain 1",
            actionId: 1,
            actionName: "Action 1",
          },
        ],
      },
      {
        id: 2,
        name: "Bundle 2",
        actions: [
          {
            domainId: 2,
            domain: "Domain 2",
            actionId: 2,
            actionName: "Action 2",
          },
        ],
      },
    ],
    serviceUrl: "https://discover.mercell.com/",
  },
  {
    id: 2,
    name: "Portfolio 2",
    priceYear: 24000,
    priceMonth: 2500,
    currency: Currency.NOK,
    trialDays: 90,
    bundles: [
      {
        id: 3,
        name: "Bundle 3",
        actions: [
          {
            domainId: 3,
            domain: "Domain 3",
            actionId: 3,
            actionName: "Action 3",
          },
        ],
      },
      {
        id: 4,
        name: "Bundle 4",
        actions: [
          {
            domainId: 4,
            domain: "Domain 4",
            actionId: 4,
            actionName: "Action 4",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: "Portfolio 3",
    priceYear: 24000,
    priceMonth: 2500,
    currency: Currency.NOK,
    trialDays: 90,
    bundles: [
      {
        id: 5,
        name: "Bundle 5",
        actions: [
          {
            domainId: 5,
            domain: "Domain 5",
            actionId: 5,
            actionName: "Action 5",
          },
        ],
      },
      {
        id: 6,
        name: "Bundle 6",
        actions: [
          {
            domainId: 6,
            domain: "Domain 6",
            actionId: 6,
            actionName: "Action 6",
          },
        ],
      },
    ],
  },
];

export const invoiceDummyData: Array<Invoice> = [
  {
    uuid: "1",
    dueDate: "14.12.2012",
    createdAt: "1.12.2012",
    price: 2300,
    currency: Currency.NOK,
    xLedgerReference: "abc123",
    isPaid: false,
    subPeriodStartedAt: "1.12.2012",
  },
];
