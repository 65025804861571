import { Link } from "react-router-dom";
import Button from "@mercell/button-react";
import React from "react";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <main className="mt-24 flex flex-grow flex-col items-center justify-center">
      <h1>{t("not_found.oh_no")}</h1>
      <h3>{t("not_found.could_not_find_page")}</h3>
      <Link to="/">
        <Button scheme="primary">{t("not_found.go_to_main_page")}</Button>
      </Link>
    </main>
  );
};
export default NotFound;
