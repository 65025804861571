import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button } from "@mercell/button-react";
import { redirectExternally } from "../../redux/RedirectSlice";

interface DashboardButtonProps {
  serviceUrl: string;
}

const DashboardButton = ({ serviceUrl }: DashboardButtonProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const redirectToDashboard = () => {
    dispatch(redirectExternally(serviceUrl));
  };
  return (
    <Button
      id="go-to-dashboard-button"
      scheme="primary"
      onClick={() => redirectToDashboard()}
      className="justify-center"
    >
      {t("checkout.receipt.go_to_dashboard")}
    </Button>
  );
};

export default DashboardButton;
