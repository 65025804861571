import * as z from "zod";

export const LoginResponseSchema = z.object({
  idToken: z.string(),
});

export const IdTokenSchema = z.object({
  sub: z.string(),
  name: z.string(),
  email: z.string(),
  email_verified: z.boolean(),
});

export type LoginResponse = z.infer<typeof LoginResponseSchema>;
export type IdToken = z.infer<typeof IdTokenSchema>;
