import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "./store";
import {
  getRedirectEmailVerifiedToFromStorage,
  getUser,
  REDIRECT_EMAIL_VERIFIED_TO,
  removeUserFromStorage,
  setUserToStorage,
  User,
} from "../domain/User";
import {
  getUserInitialLocale,
  Locale,
  setLocaleToStorage,
} from "../domain/Country";

import { LOGOUT_ENDPOINT, REFRESH_TOKEN_ENDPOINT } from "../config/constants";
import { RequestStatus } from "./common";
import { authResponseToUser, getLogoutRedirectUrl } from "../api/Auth";
import { redirectExternally } from "./RedirectSlice";
import { removeRedirectAfterCheckoutToFromStorage } from "../domain/Subscription";

export interface UsersState {
  user: User | null;
  loginFailed: boolean;
  authenticationProcessedFailed: boolean;
  locale: Locale;
  logoutStatus: RequestStatus;
  refreshTokensStatus: RequestStatus;
  redirectEmailVerifiedTo: string | null;
}

const initialState: UsersState = {
  user: getUser(),
  loginFailed: false,
  authenticationProcessedFailed: false,
  locale: getUserInitialLocale(),
  logoutStatus: RequestStatus.NOT_INITIATED,
  refreshTokensStatus: RequestStatus.NOT_INITIATED,
  redirectEmailVerifiedTo: getRedirectEmailVerifiedToFromStorage(),
};

export const logoutBackendAndStorage = async () => {
  const result: AxiosResponse<unknown> = await axios.post(LOGOUT_ENDPOINT);
  removeUserFromStorage();
  return result;
};

export const logout = createAsyncThunk(
  "user/logout",
  async (_arg, thunkApi) => {
    try {
      const result = await logoutBackendAndStorage();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      thunkApi.dispatch<any>(redirectExternally(getLogoutRedirectUrl()));
      return result.data;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const logoutInternally = createAsyncThunk(
  "user/logout",
  async (_arg, thunkApi) => {
    try {
      const result = await logoutBackendAndStorage();
      return result.data;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const refreshTokens = createAsyncThunk(
  "checkout/refreshTokens",
  async (_arg, thunkApi) => {
    try {
      const result = await axios.post(REFRESH_TOKEN_ENDPOINT);
      return authResponseToUser(result.data);
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
      state.loginFailed = false;
      state.authenticationProcessedFailed = false;
      setUserToStorage(action.payload);
    },
    setLocale(state, action: PayloadAction<Locale>) {
      state.locale = action.payload;
      setLocaleToStorage(action.payload);
    },
    setLoginFailed(state) {
      state.loginFailed = true;
    },
    setAuthenticationProcessFailed(state) {
      state.authenticationProcessedFailed = true;
    },
    clearAuthenticationProcessFailed(state) {
      state.authenticationProcessedFailed = false;
    },
    setRedirectEmailVerifiedTo(state, action: PayloadAction<string>) {
      const urlString = action.payload;
      localStorage.setItem(REDIRECT_EMAIL_VERIFIED_TO, urlString);
      state.redirectEmailVerifiedTo = urlString;
    },
    removeRedirectEmailVerifiedTo(state) {
      removeRedirectAfterCheckoutToFromStorage();
      state.redirectEmailVerifiedTo = null;
    },
  },
  extraReducers: {
    [logout.pending.toString()]: (state) => {
      state.logoutStatus = RequestStatus.PENDING;
    },
    [logout.fulfilled.toString()]: (state) => {
      state.user = null;
      state.loginFailed = false;
      state.logoutStatus = RequestStatus.SUCCESS;
    },
    [logout.rejected.toString()]: (state) => {
      state.logoutStatus = RequestStatus.FAILURE;
    },
    [refreshTokens.pending.toString()]: (state) => {
      state.refreshTokensStatus = RequestStatus.PENDING;
    },
    [refreshTokens.fulfilled.toString()]: (
      state,
      action: PayloadAction<User>
    ) => {
      state.user = action.payload;
      setUserToStorage(action.payload);
      state.refreshTokensStatus = RequestStatus.SUCCESS;
    },
    [refreshTokens.rejected.toString()]: (state) => {
      state.refreshTokensStatus = RequestStatus.FAILURE;
    },
  },
});

export const {
  setUser,
  setLocale,
  setLoginFailed,
  setAuthenticationProcessFailed,
  clearAuthenticationProcessFailed,
  setRedirectEmailVerifiedTo,
  removeRedirectEmailVerifiedTo,
} = userSlice.actions;

export const selectUser = (state: RootState) => state.user.user;
export const selectLocale = (state: RootState) => state.user.locale;
export const selectIsLoggedIn = (state: RootState) => !!state.user.user;
export const selectLoginFailed = (state: RootState) => state.user.loginFailed;
export const selectAuthenticationProcessFailed = (state: RootState) =>
  state.user.authenticationProcessedFailed;
export const selectLogoutFailed = (state: RootState) =>
  state.user.logoutStatus === RequestStatus.FAILURE;
export const selectRefreshTokensStatus = (state: RootState) =>
  state.user.refreshTokensStatus;
export const selectIsAuthCompleted = (state: RootState) =>
  selectIsLoggedIn(state) ||
  selectLoginFailed(state) ||
  selectAuthenticationProcessFailed(state);
export const selectRedirectEmailVerifiedTo = (state: RootState) =>
  state.user.redirectEmailVerifiedTo;
export default userSlice.reducer;
