import React, { useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getAuth0State,
  getRedirectBackUrl,
  loginAuth0,
  prepareLoginWithRedirectUrl,
  removeAuth0State,
} from "../api/Auth";
import {
  clearAuthenticationProcessFailed,
  selectIsAuthCompleted,
  setAuthenticationProcessFailed,
  setLoginFailed,
  setRedirectEmailVerifiedTo,
  setUser,
} from "../redux/UserSlice";
import { storeAdditionalQueryStrings } from "../utils/query";
import {
  redirectExternally,
  redirectInternally,
  selectIsRedirectInProgress,
} from "../redux/RedirectSlice";
import { USER_VERIFY_EMAIL_URL } from "../config/constants";

interface ContentWrapperProps {
  children: React.ReactNode;
}

export type IsWaitingForRedirect = boolean;

const AuthWrapper = ({ children }: ContentWrapperProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location]);

  const auth0Code = query.get("code");
  const authState = query.get("state");
  const auth0Error = query.get("error");
  const store = useStore();
  useEffect(() => {
    storeAdditionalQueryStrings(store, query);
  }, [store, query]);
  const isAuthCompleted = useSelector(selectIsAuthCompleted);
  const isRedirectInProgress = useSelector(selectIsRedirectInProgress);
  useEffect(() => {
    dispatch(clearAuthenticationProcessFailed());
  }, [dispatch]);
  const showContent = isAuthCompleted && !isRedirectInProgress;

  useEffect(() => {
    const handleAuth = async () => {
      if (!isAuthCompleted) {
        const expectingRedirectToHappen: IsWaitingForRedirect =
          (await completeLoginIfNeeded()) ||
          (await initiateLoginIfNeeded()) ||
          (await redirectToSavedPageIfNeeded());
        if (!expectingRedirectToHappen) {
          dispatch(setAuthenticationProcessFailed());
        }
      }
    };
    const completeLoginIfNeeded = async (): Promise<IsWaitingForRedirect> => {
      // if state and code is present, it means we are handling the login redirect from auth0
      if (authState && auth0Code) {
        try {
          const user = await loginAuth0(authState, auth0Code);
          if (user) {
            dispatch(setUser(user));
            const url = getRedirectBackUrl(authState);
            if (!user.emailVerified) {
              dispatch(setRedirectEmailVerifiedTo(url));
              dispatch(redirectInternally(USER_VERIFY_EMAIL_URL, history));
            } else {
              dispatch(redirectInternally(url, history));
            }
          }
        } catch (error) {
          console.error(`Login failed ${error}`);
          dispatch(setLoginFailed());
        }
        return true;
      }
      return false;
    };
    const redirectToSavedPageIfNeeded =
      async (): Promise<IsWaitingForRedirect> => {
        // if error is present, it means we already tried to login silently but user is not authenticated on auth0
        // so we need to redirect user back to the previous page
        const state = getAuth0State();
        if (auth0Error && !isLogoutPage() && state) {
          removeAuth0State();
          const url = getRedirectBackUrl(state, true);
          dispatch(redirectInternally(url, history));
          return true;
        }
        return false;
      };
    const initiateLoginIfNeeded = async (): Promise<IsWaitingForRedirect> => {
      // if error is not present, it means the user tries to visit the page for the first time
      const shouldInitiateBackgroundAuthentication =
        !auth0Error && !isLogoutPage();
      const authenticationForPageRequired =
        isCheckoutPage() || isVerifyEmailPage() || isEmailVerifiedPage();
      if (
        shouldInitiateBackgroundAuthentication ||
        authenticationForPageRequired
      ) {
        // for auth pages we force users to login so must not skip prompt
        const url = prepareLoginWithRedirectUrl(
          authenticationForPageRequired,
          window.location.pathname
        );
        dispatch(redirectExternally(url));
        return true;
      }
      return false;
    };
    const isLogoutPage = () => location.pathname.includes("logout");
    const isCheckoutPage = () => location.pathname.includes("checkout");
    const isVerifyEmailPage = () => location.pathname.includes("verify_email");
    const isEmailVerifiedPage = () =>
      location.pathname.includes("email_verified");
    handleAuth();
  }, [
    location,
    auth0Error,
    auth0Code,
    authState,
    dispatch,
    history,
    isAuthCompleted,
  ]);

  return showContent ? <>{children}</> : <></>;
};
export default AuthWrapper;
