import React from "react";
import InvoiceTable from "./InvoiceTable";

const InvoiceHistory = () => {
  return (
    <div className="shadow-lg p-10">
      <h3>InvoiceHistory</h3>
      <InvoiceTable />
    </div>
  );
};

export default InvoiceHistory;
