import axios, { AxiosError } from "axios";
import { prepareLoginWithRedirectUrl } from "./Auth";
import {
  logoutInternally,
  setAuthenticationProcessFailed,
} from "../redux/UserSlice";
import { redirectExternally } from "../redux/RedirectSlice";
import { Store } from "../redux/store";
import { USER_VERIFY_EMAIL_URL } from "../config/constants";

export const initAxios = (store: Store) => {
  axios.defaults.withCredentials = true;
  axios.interceptors.response.use((response) => {
    return response;
  }, handleUnauthorized(store));
};

export const setAccessTokenRetrievalForAxios = (
  getAccessToken: () => Promise<string | null>,
  getIdToken: () => Promise<string | null>
) => {
  axios.interceptors.request.use(async (config) => {
    try {
      const token = await getAccessToken();
      if (token) config.headers.Authorization = `Bearer ${token}`;
      const idToken = await getIdToken();
      if (idToken) config.headers.idToken = idToken;
    } catch (e) {
      console.warn(`cannot get access token, ${e}`);
    }

    return config;
  });
};

const EMAIL_NOT_VERIFIED = "EMAIL_NOT_VERIFIED";

export const handleUnauthorized =
  (store: Store) => async (error: AxiosError) => {
    if (error.response?.status === 401) {
      if (error.response.data?.message === EMAIL_NOT_VERIFIED) {
        handleEmailNotVerified(store);
      }
      await handleGeneralUnauthorized(store);
    }
    throw error;
  };

export const handleEmailNotVerified = (store: Store) => {
  store.dispatch(redirectExternally(USER_VERIFY_EMAIL_URL));
};
export const handleGeneralUnauthorized = async (store: Store) => {
  // so that we don't try to initiate login again
  store.dispatch(setAuthenticationProcessFailed());
  await store.dispatch(logoutInternally());
  const url = prepareLoginWithRedirectUrl(true, window.location.pathname);
  store.dispatch(redirectExternally(url));
};

export default axios;
