import React from "react";
import { useTranslation } from "react-i18next";
import { Portfolio } from "../../domain/Portfolio";
import Summary from "./Summary";
import DashboardButton from "./DashboardButton";
import personWithLaptopAndCoffee from "../../assets/images/person_with_laptop_and_coffee.png";

interface ReceiptProps {
  portfolio: Portfolio;
}

const Receipt = ({ portfolio }: ReceiptProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center">
      <h1 className="text-center">{t("checkout.receipt.title")}</h1>
      <div className="mt-10 justify-center">
        <div className="w-auto">
          {t("checkout.receipt.thanks_for_choosing_the_product")}{" "}
          {portfolio.name}. <br />
          {t("checkout.receipt.we_will_help_to_get_the_most_value")}.
        </div>
      </div>
      <img
        src={personWithLaptopAndCoffee}
        alt={t("image.person_with_laptop_and_coffee")}
        style={{ minWidth: "500px" }}
        className="w-4/12 my-10"
      />
      {portfolio?.serviceUrl && (
        <div className="max-w-6xl justify-center mb-8 mt-12">
          <DashboardButton serviceUrl={portfolio?.serviceUrl} />
        </div>
      )}
      <div className="w-full max-w-6xl">
        <Summary portfolio={portfolio} />
      </div>
    </div>
  );
};

export default Receipt;
