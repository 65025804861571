import { useTranslation, Trans } from "react-i18next";
import React from "react";
import Card from "@mercell/card-react";

const InformationForm = () => {
  const { t } = useTranslation();
  // Get this information from somewhere instead of hardcode it?
  const callForm =
    "https://mercell.atlassian.net/servicedesk/customer/portal/7/group/77/create/173";
  const phone = "+47 210 18 800";
  const servicePage =
    "https://mercell.atlassian.net/servicedesk/customer/portal/7";
  return (
    <Card className="grey-card">
      <h3 className="mb-3">{t("checkout.information.heading")}</h3>
      <span className="small-text">
        <Trans i18nKey="checkout.information.call-text">
          Call us on <a href={`tel:${phone}`}>{phone}</a> or
          <a href={callForm} rel="noreferrer" target="_blank">
            get called by us
          </a>
        </Trans>
      </span>
      <p className="small-text">
        <Trans i18nKey="checkout.information.service-help">
          Learn more about
          <a href={servicePage} rel="noreferrer" target="_blank">
            Mercell Norge Customer Service
          </a>
        </Trans>
      </p>
      <h4 className="mt-5">
        {t("checkout.information.opening_hours.heading")}
      </h4>
      <p className="small-text pb-1">
        {t("checkout.information.opening_hours.value")}
      </p>
    </Card>
  );
};

export default InformationForm;
