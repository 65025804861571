import { ChevronDown24, ChevronUp24, Wikis24 } from "@carbon/icons-react";
import Button from "@mercell/button-react";
import { DropdownMenu } from "@mercell/dropdown-menu-react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { Locale } from "../../domain/Country";

export interface LanguageSelectorProps {
  locale: Locale;
  locales: Locale[];
  label: string;
  id: string;
  onClick: (locale: Locale) => void;
}

const LanguageSelector = ({
  locale,
  locales,
  className,
  label,
  id,
  onClick,
  ...rest
}: LanguageSelectorProps & Omit<JSX.IntrinsicElements["nav"], "onClick">) => {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);
  const toggleShow = () => setShow(!show);
  const hideMenu = () => setShow(false);

  return (
    <nav className={cx("relative", className)} {...rest} aria-label={label}>
      <Button
        scheme="tertiary"
        aria-label={label}
        className="flex items-center px-4 space-x-2 interactive"
        aria-controls={id}
        onClick={toggleShow}
      >
        <Wikis24 />
        <span className="uppercase">{locale.languageName.slice(0, 2)}</span>
        {show ? (
          <ChevronUp24 className="hidden sm:block" />
        ) : (
          <ChevronDown24 className="hidden sm:block" />
        )}
      </Button>
      {show && (
        <DropdownMenu id={id} onClose={() => setShow(false)}>
          {locales.map((l) => (
            <li key={l.country} className="hover:bg-secondary-hover">
              <a
                href={`#${l.country}`}
                className="px-4 interactive w-full flex items-center space-x-2"
                onClick={(e) => {
                  e.preventDefault();
                  onClick(l);
                  hideMenu();
                }}
              >
                <span>{l.languageName}</span>
              </a>
            </li>
          ))}
          <li className="sm:hidden">
            <button
              type="button"
              className="interactive py-4 w-full flex items-center justify-center"
              onClick={hideMenu}
            >
              {t("close_menu")}
            </button>
          </li>
        </DropdownMenu>
      )}
    </nav>
  );
};

export default LanguageSelector;
