import { useTranslation } from "react-i18next";
import Card from "@mercell/card-react";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "@mercell/checkbox-react";
import { DropdownMenu } from "@mercell/dropdown-menu-react";
import { AddAlt20, Close32, SubtractAlt20 } from "@carbon/icons-react";
import useHotkeys from "@reecelucas/react-use-hotkeys";
import {
  organizationNumberValidator,
  phoneNumberValidator,
  selectAddress,
  selectCity,
  selectCountryCode,
  selectIsEligibleForSubscription,
  selectName,
  selectOrganizationNumber,
  selectPhone,
  selectPortfolio,
  selectPostalCode,
  setAddress,
  setCity,
  setCountryCode,
  setName,
  setOrganizationName,
  setOrganizationNumber,
  setPhone,
  setPostalCode,
  setTermsAccepted,
} from "../../redux/CheckoutSlice";
import { BillingPeriod } from "../../domain/Portfolio";
import { Organisation } from "../../domain/organisation";
import {
  searchForOrganisationName,
  searchForOrganisationNumber,
} from "../../api/OseApi";
import { Locale, NORWAY_LOCALE } from "../../domain/Country";
import { selectFlag } from "../../redux/LauchDarklySlice";
import { Flags } from "../../domain/flags";

const InvoiceForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const locale = NORWAY_LOCALE;
  const [organisationSearchValue, setOrganisationSearchValue] = useState("");
  const [selectedOrganisation, setSelectedOrganisation] =
    useState<Organisation>();
  const organizationNumber = useSelector(selectOrganizationNumber);
  const isEligibleForSubscription = useSelector(
    selectIsEligibleForSubscription
  );
  const ADMIN_ENABLE_MULTIPLE_SUBSCRIPTIONS = useSelector(
    selectFlag(Flags.ADMIN_ENABLE_MULTIPLE_SUBSCRIPTIONS)
  );
  const portfolio = useSelector(selectPortfolio);
  const [organisationAutoCompleteOptions, setOrganisationAutoCompleteOptions] =
    useState<Organisation[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [displayContactInfoFrom, setDisplayContactInfoFrom] = useState(false);
  const [phoneValid, setPhoneValid] = useState(true);
  const [showNotFoundMessage, setShowNotFoundMessage] = useState(false);
  const [termsAcceptedValid, setTermsAcceptedValid] = useState(true);

  const displayOrganisationRegistry = (l: Locale) => {
    switch (l) {
      case NORWAY_LOCALE:
        return "(Brønnøysund)";
      default:
        return "";
    }
  };
  const selectOrganisation = (org: Organisation) => {
    setShowNotFoundMessage(false);
    setSelectedOrganisation(org);
    dispatch(setCity(org.address.city));
    dispatch(setCountryCode(org.address.countryCode));
    dispatch(setPostalCode(org.address.postalCode));
    dispatch(setAddress(org.address.addressLine));
    dispatch(setOrganizationName(org.name));
    dispatch(setOrganizationNumber(org.organisationNumber));
  };
  const clearValues = (clearSearchField = false) => {
    setSelectedOrganisation(undefined);
    dispatch(setCity(""));
    dispatch(setPostalCode(""));
    dispatch(setAddress([]));
    dispatch(setOrganizationName(""));
    dispatch(setOrganizationNumber(""));
    dispatch(setCountryCode(locale.country));
    if (clearSearchField) {
      setOrganisationSearchValue("");
    }
  };

  const organisationNotFoundCallback = () => {
    setShowNotFoundMessage(true);
    clearValues(false);
  };

  const setFocusOn = (ref: React.MutableRefObject<HTMLInputElement | null>) => {
    if (ref.current !== null) {
      ref.current.focus();
    }
  };

  const searchInput: React.MutableRefObject<HTMLInputElement | null> =
    React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    setFocusOn(searchInput);
  }, []);

  const onOrganisationSearchValueChange = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    setIsSearching(true);
    setOrganisationSearchValue(value);
    if (organizationNumberValidator(value, locale.country)) {
      searchForOrganisationNumber(
        value,
        locale.country,
        selectOrganisation,
        organisationNotFoundCallback
      );
      setIsSearching(false);
    } else {
      dispatch(setOrganizationName(value));
      searchForOrganisationName(
        value,
        locale.country,
        (a) => {
          setOrganisationAutoCompleteOptions(a);
          if (a.length === 0) {
            organisationNotFoundCallback();
          } else {
            setShowNotFoundMessage(false);
          }
        },
        organisationNotFoundCallback
      );
    }
  };

  const name = useSelector(selectName);
  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    dispatch(setName(value));
  };

  const address = useSelector(selectAddress);
  const postalCode = useSelector(selectPostalCode);
  const city = useSelector(selectCity);
  const countryCode = useSelector(selectCountryCode);
  const phone = useSelector(selectPhone);

  const onPhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPhoneValid(phoneNumberValidator(value));
    if (!phoneNumberValidator(value)) {
      e.target.setCustomValidity(t("checkout.form.incorrect_phone_number"));
    } else {
      e.target.setCustomValidity("");
    }
    dispatch(setPhone(value));
  };
  const onTermsAcceptChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    const value: boolean = target.type === "checkbox" ? target.checked : false;
    if (value) {
      e.target.setCustomValidity("");
    } else {
      e.target.setCustomValidity(t("checkout.form.must_accept_terms"));
    }
    dispatch(setTermsAccepted(value));
    setTermsAcceptedValid(value);
  };

  const addressLines = address.map((line, i) => {
    if (i === 0) {
      return (
        <div key={line}>
          {t("checkout.form.address")}: {line}
        </div>
      );
    }
    return <div>{line}</div>;
  });

  const clearSearch = () => {
    setShowNotFoundMessage(false);
    setFocusOn(searchInput);
    clearValues(true);
  };

  const setOrganisation = (organisation: Organisation) => {
    setIsSearching(false);
    selectOrganisation(organisation);
  };

  const handleArrowKeyNavigation = (
    event: React.KeyboardEvent<HTMLLIElement>
  ) => {
    if (event.key === "ArrowDown") {
      (event.currentTarget.nextSibling as HTMLLIElement).focus();
    }

    if (event.key === "ArrowUp") {
      (event.currentTarget.previousSibling as HTMLLIElement).focus();
    }
  };

  useHotkeys("Shift+Control+f", (event) => {
    setFocusOn(searchInput);
    setIsSearching(true);
    setShowNotFoundMessage(false);
    event.preventDefault();
  });

  useHotkeys("Shift+Control+Backspace", (event) => {
    setShowNotFoundMessage(false);
    clearValues(true);
    setOrganisationAutoCompleteOptions([]);
    setFocusOn(searchInput);
    event.preventDefault();
  });

  return (
    <Card>
      <h3 className="pt-2 pb-4">{t("checkout.checkout_header")}</h3>

      {!ADMIN_ENABLE_MULTIPLE_SUBSCRIPTIONS && !isEligibleForSubscription && (
        <div className="rounded p-6 pt-6 pb-4 my-8 w-full information-blue">
          <h3>
            {portfolio
              ? `${t("checkout.form.already_active_subscription_with")} ${
                  portfolio.name
                }`
              : t("checkout.form.already_active_subscription")}
            .
          </h3>
        </div>
      )}

      <p>
        {t("checkout.form.find_your_organisation_description_paragraph_one")}
      </p>
      <p>
        {t("checkout.form.find_your_organisation_description_paragraph_two")}
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 grid-flow-row gap-4 pt-6">
        <label htmlFor="organisation-search" className="relative">
          <p className="pb-1">
            {t("checkout.form.find_your_organisation")}{" "}
            {displayOrganisationRegistry(locale)}
          </p>
          <div className="input-icon">
            <input
              ref={searchInput}
              id="organisation-search input-field"
              className="w-full"
              value={organisationSearchValue}
              name="organisation-search"
              autoComplete="off"
              placeholder={t("checkout.form.search-placeholder")}
              onChange={onOrganisationSearchValueChange}
              disabled={
                !ADMIN_ENABLE_MULTIPLE_SUBSCRIPTIONS &&
                !isEligibleForSubscription
              }
            />
            {organisationSearchValue.length > 0 && (
              <Close32 onClick={clearSearch} />
            )}
          </div>

          {showNotFoundMessage && (
            <div className="text-invalid ml-4 mt-2">
              {t("checkout.form.organisation_not_found")}
            </div>
          )}
          {organisationAutoCompleteOptions.length > 0 && isSearching && (
            <DropdownMenu
              id="selectOrganisationMenu"
              style={{
                border: "1px solid black",
                padding: "0 0.5em",
              }}
              className="left-0 bg-base rounded"
              onClose={() => {
                setIsSearching(false);
              }}
            >
              {organisationAutoCompleteOptions.map((organisation) => (
                <li
                  tabIndex={0}
                  value={organisation.organisationNumber}
                  className="autocomplete-item rounded"
                  onKeyUp={(event) => {
                    if (event && event.key === "Enter") {
                      setOrganisation(organisation);
                    }
                    handleArrowKeyNavigation(event);
                  }}
                  key={organisation.organisationNumber}
                  onClick={() => {
                    setOrganisation(organisation);
                  }}
                >
                  {organisation.name} ({organisation.organisationNumber})
                </li>
              ))}
            </DropdownMenu>
          )}
        </label>
      </div>
      {selectedOrganisation && (
        <div className="rounded p-6 pt-6 pb-4 my-8 w-full information-blue">
          <h3>{selectedOrganisation.name}</h3>
          <div>
            {t("checkout.form.organisation_number")}: {organizationNumber}
          </div>
          <div>{addressLines}</div>
          <div>
            {postalCode} {city}, {t(`country.${countryCode}`)}
          </div>

          {!displayContactInfoFrom && (
            <button
              type="button"
              className="mt-4"
              onClick={() => {
                setDisplayContactInfoFrom(true);
              }}
            >
              <div className="flex items-center">
                <AddAlt20 />
                &nbsp;
                {t("checkout.form.add_contact_info")}
              </div>
            </button>
          )}
          {displayContactInfoFrom && (
            <div className="mt-8">
              <h3 className="mb-1">
                {t("checkout.invoice_contact_information_title")}
              </h3>
              <label htmlFor="name" className="py-4 mt-10">
                {t("checkout.form.contact_person")}
                <input
                  id="name"
                  className="w-full"
                  value={name}
                  onChange={onNameChange}
                />
              </label>
              <div className="pb-2" />
              <label htmlFor="organisation-phone" className="py-16">
                {t("checkout.form.phone_label")}
                <input
                  style={{ minHeight: 47 }}
                  id="organisation-phone"
                  className="w-full"
                  onChange={onPhoneChange}
                  value={phone || ""}
                />
                {!phoneValid && (
                  <div className="text-invalid ml-4 mt-2">
                    {t("checkout.form.incorrect_phone_number")}
                  </div>
                )}
              </label>
              <button
                type="button"
                className="mt-4"
                onClick={() => {
                  dispatch(setName(""));
                  dispatch(setPhone(""));
                  setDisplayContactInfoFrom(false);
                }}
              >
                <div className="flex items-center">
                  <SubtractAlt20 />
                  &nbsp;
                  {t("checkout.form.remove_contact_info")}
                </div>
              </button>
            </div>
          )}
        </div>
      )}
      <div style={{ display: "none" }}>
        <input
          id="organisation-name"
          className="w-full"
          value={selectedOrganisation?.name ?? ""}
          type="hidden"
        />
        <input
          id="organisation-number"
          className="w-full"
          value={organizationNumber}
          type="hidden"
        />
        <input
          id="organisation-address"
          className="w-full"
          value={address}
          type="hidden"
        />
        <input
          id="organisation-postal-code"
          className="w-full"
          value={postalCode}
          type="hidden"
        />
        <input
          id="organisation-postal-city"
          className="w-full"
          value={city}
          type="hidden"
        />
        <input
          id="organization-country"
          className="w-full text-main-40"
          value={countryCode}
          type="hidden"
        />
        <input
          id="monthly-invoice"
          name="invoice-frequency-group"
          value={BillingPeriod.YEARLY}
          type="hidden"
        />
      </div>

      <div className="pt-10">
        <label htmlFor="accept-terms" className="mt-6 terms-heading">
          <Checkbox
            id="accept-terms"
            onChange={onTermsAcceptChange}
            tabIndex={0}
          >
            {t("checkout.form.accept_terms")}
          </Checkbox>
        </label>
        <div className="pt-3.5 ml-7 ">
          <p className="small-text pb-1">
            {t("checkout.terms_text")}
            &nbsp;
            <a
              className="terms-link"
              target="_blank"
              href="https://www.mercell.com/nb-no/82033630/vaare-vilkaar-og-betingelser.aspx"
              rel="noreferrer"
            >
              {t("checkout.form.terms_link_description")}
            </a>
          </p>

          {!termsAcceptedValid && (
            <div className="text-invalid ml-4 mt-2">
              {t("checkout.form.must_accept_terms")}
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default InvoiceForm;
