import React from "react";
import InvoiceHistory from "./invoice_history/InvoiceHistory";

const MyPage = () => {
  return (
    <main className="mt-24 flex flex-grow flex-col w-11/12 md:w-10/12">
      <h3>MyPage</h3>
      <InvoiceHistory />
    </main>
  );
};

export default MyPage;
