import React from "react";
import SelectedPlan from "./SelectedPlan";
import CheckoutButton from "./CheckoutButton";
import InvoiceForm from "./InvoiceForm";

const InputBlock = () => {
  return (
    <div className="flex flex-col text-brand-dark">
      <SelectedPlan />
      <div className="pb-3" />
      <InvoiceForm />
      <div className="flex justify-end mt-3">
        <CheckoutButton />
      </div>
    </div>
  );
};

export default InputBlock;
