export const API_HOST =
  (import.meta.env.VITE_API_HOST as string) ?? "http://localhost:8080";
export const LAUNCH_DARKLY_ENV_KEY: string =
  (import.meta.env.VITE_LAUNCH_DARKLY_ENV_KEY as string) ?? "";
export const API_VERSION = "v1";
export const API_PREFIX = `${API_HOST}/api/${API_VERSION}`;
export const PORTFOLIO_GET_ENDPOINT = `${API_PREFIX}/portfolio`;
export const MY_PURCHASES_GET_ENDPOINT = `${API_PREFIX}/mypurchases`;
export const ORG_SEARCH_ORGNR_GET_ENDPOINT = `${API_HOST}/api/organisation`;
export const ORG_SEARCH_NAME_GET_ENDPOINT = `${API_HOST}/api/organisationsearch`;
export const SUBSCRIPTION_ENDPOINT = `${API_PREFIX}/subscription`;
export const LOGIN_ENDPOINT = `${API_PREFIX}/login`;
export const LOGOUT_ENDPOINT = `${API_PREFIX}/logout`;
export const REFRESH_TOKEN_ENDPOINT = `${API_PREFIX}/refresh-tokens`;
export const USER_VERIFY_EMAIL_URL = "/verify_email";
export const INVOICE_ENDPOINT = `${API_PREFIX}/invoice`;
