import { BillingPeriod } from "../domain/Portfolio";

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getRandomString = (length: number) => {
  const bytes = new Uint8Array(length);
  const charset =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~";

  const cryptoObj = window.crypto;
  if (!cryptoObj) return null;

  const random = cryptoObj.getRandomValues(bytes);

  return random.map((v) => v % charset.length).join("");
};

export function getPeriodPriceSuffix(billingPeriod: BillingPeriod) {
  switch (billingPeriod) {
    case BillingPeriod.YEARLY:
      return "checkout.summary.yearly";
    case BillingPeriod.MONTHLY:
      return "checkout.summary.monthly";
  }
}
