import { ENGLAND_LOCALE } from "../domain/Country";

export function formatPrice(
  price: number,
  currency: string,
  locale: string
): string {
  try {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    }).format(price);
  } catch (e) {
    return Intl.NumberFormat(ENGLAND_LOCALE.locale, {
      style: "currency",
      currency: "GBP",
    }).format(price);
  }
}
