import jwtDecode from "jwt-decode";
import axios from "axios";
import { User } from "../domain/User";
import { LOGIN_ENDPOINT } from "../config/constants";
import { getRandomString } from "../utils/strings";
import { validate } from "../redux/common";
import { IdTokenSchema, LoginResponseSchema } from "../domain/auth";

export const LOGOUT_PATH = "/logout";
const AUTH0_STATE_KEY = `AUTH0_STATE`;

const getReturnToLogout = () =>
  encodeURIComponent(`${window.location.origin}${LOGOUT_PATH}`);

const getRedirectUri = () => encodeURIComponent(`${window.location.origin}`);

export const getLoginRedirectUrl = (state: string, prompt = false) =>
  `https://${import.meta.env.VITE_AUTH0_DOMAIN}/authorize
?response_type=code
&client_id=${import.meta.env.VITE_AUTH0_CLIENT_ID}
&redirect_uri=${getRedirectUri()}
&state=${state}
${prompt ? "" : "&prompt=none"}
&scope=openid profile email offline_access
${
  import.meta.env.VITE_AUTH0_AUDIENCE
    ? `&audience=https://${import.meta.env.VITE_AUTH0_AUDIENCE}`
    : ""
}`;

export const getLogoutRedirectUrl = () =>
  `https://${import.meta.env.VITE_AUTH0_DOMAIN}/v2/logout?client_id=${
    import.meta.env.VITE_AUTH0_CLIENT_ID
  }&returnTo=${getReturnToLogout()}`;

export const prepareLoginWithRedirectUrl = (prompt = false, path = "/") => {
  const state = getEncodedPathStateString(64, path);
  const link = getLoginRedirectUrl(state, prompt);
  storeAuth0State(state);
  return link;
};

export const getPathFromEncodedState = (encodedState: string) => {
  if (encodedState.includes(":")) {
    return atob(encodedState.split(":")[0]);
  }
  return "/";
};

export const getRedirectBackUrl = (
  authState: string,
  authenticationError = false
) => {
  // when we land to our site after a successful or error login, we need to redirect to the url where the user was
  const path = getPathFromEncodedState(authState);
  const url = new URL(window.location.origin + path);
  if (authenticationError) {
    // if it's an error redirect, we also attach queryString to stop trying to redirect
    url.searchParams.set("error", "anonymous_user");
  }
  return url.pathname + url.search;
};

export const getEncodedPathStateString = (length: number, path: string) => {
  const encodedPath = btoa(path);
  const randomString = getRandomString(length - encodedPath.length);
  return `${encodedPath}:${randomString}`;
};

export const storeAuth0State = (state: string) => {
  localStorage.setItem(AUTH0_STATE_KEY, state);
};
export const getAuth0State = () => localStorage.getItem(AUTH0_STATE_KEY);

export const removeAuth0State = () => {
  localStorage.removeItem(AUTH0_STATE_KEY);
};

export const authResponseToUser = (data: Record<string, unknown>): User => {
  const validatedData = validate(data, LoginResponseSchema);
  const { idToken } = validatedData;
  const validatedIdToken = validate(jwtDecode(idToken), IdTokenSchema);
  return {
    id: validatedIdToken.sub,
    name: validatedIdToken.name,
    email: validatedIdToken.email,
    emailVerified: validatedIdToken.email_verified,
  };
};

export const loginAuth0 = async (state: string, code: string) => {
  if (getAuth0State() === state) {
    const response = await axios.post(LOGIN_ENDPOINT, { code });
    const user = authResponseToUser(response.data);
    removeAuth0State();
    return user;
  }
  console.error(
    `State for login attempt incorrect or missing desired: ${state}, current:${getAuth0State()}`
  );
};
