import { REDIRECT_AFTER_CHECKOUT_TO_KEY } from "../domain/Subscription";
import { setRedirectAfterCheckoutTo } from "../redux/CheckoutSlice";
import { Store } from "../redux/store";

export function storeAdditionalQueryStrings(
  store: Store,
  query: URLSearchParams
) {
  const redirectAfterCheckoutTo = query.get(REDIRECT_AFTER_CHECKOUT_TO_KEY);
  if (redirectAfterCheckoutTo) {
    store.dispatch(setRedirectAfterCheckoutTo(redirectAfterCheckoutTo));
  }
}
