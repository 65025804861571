import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { BillingPeriod, Portfolio } from "../domain/Portfolio";
import { SUBSCRIPTION_ENDPOINT } from "../config/constants";
import { RequestStatus } from "./common";
import { RootState } from "./store";
import {
  CountryCode,
  getLocaleByCountry,
  getUserInitialLocale,
} from "../domain/Country";
import {
  getRedirectAfterCheckoutToFromStorage,
  REDIRECT_AFTER_CHECKOUT_TO_KEY,
  removeRedirectAfterCheckoutToFromStorage,
  Subscription,
} from "../domain/Subscription";
import { selectLocale } from "./UserSlice";
import {
  validateEmail,
  validateOrgNumber,
  validatePhoneNumber,
  validatePostalCode,
} from "../domain/validators";

export interface CheckoutState {
  portfolio: Portfolio | null;
  billingPeriod: BillingPeriod;
  organizationNumber: string;
  organizationName: string;
  name: string;
  invoiceEmail: string;
  address: string[];
  postalCode: string;
  city: string;
  countryCode: CountryCode;
  phone: string | null;
  phonePlaceHolder: string;
  createSubscriptionStatus: RequestStatus;
  redirectAfterCheckoutTo: string | null;
  isTrial: boolean;
  isTermsAccepted: boolean;
  isEligibleForSubscription: boolean;
}

const initialState: CheckoutState = {
  isTrial: false, // TODO extract from query param
  portfolio: null,
  billingPeriod: BillingPeriod.YEARLY,
  organizationNumber: "",
  organizationName: "",
  name: "",
  invoiceEmail: "",
  address: [],
  postalCode: "",
  city: "",
  countryCode: CountryCode.NORWAY,
  phone: null,
  phonePlaceHolder: "",
  createSubscriptionStatus: RequestStatus.NOT_INITIATED,
  redirectAfterCheckoutTo: getRedirectAfterCheckoutToFromStorage(),
  isTermsAccepted: false,
  isEligibleForSubscription: true,
};

export function getPriceForBillingPeriod(
  portfolio: Portfolio,
  billingPeriod: BillingPeriod
) {
  switch (billingPeriod) {
    case BillingPeriod.MONTHLY:
      return portfolio.priceMonth;
    case BillingPeriod.YEARLY:
      return portfolio.priceYear;
  }
}

export const getActiveSubscriptionsByPortfolioId = createAsyncThunk(
  "get/activeSubscriptions",
  async (portfolioId: number) => {
    const response = await axios.get<Subscription[]>(
      `${SUBSCRIPTION_ENDPOINT}?portfolioId=${portfolioId}&isCancelled=false`
    );
    return response.data;
  }
);

export const createSubscription = createAsyncThunk(
  "checkout/createSubscription",
  async (_arg, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const subscriptionArgs = {
      portfolioId: selectPortfolio(state)?.id,
      billingPeriod: selectBillingPeriod(state),
      organizationNumber: selectOrganizationNumber(state),
      organizationName: selectOrganizationName(state),
      organizationCountry: getUserInitialLocale().country,
      name: selectName(state),
      invoiceEmail: selectInvoiceEmail(state),
      addressLines: selectAddress(state),
      postalCode: selectPostalCode(state),
      city: selectCity(state),
      countryCode: selectCountryCode(state),
      phone: selectPhone(state) || "",
      locale: selectLocale(state)?.locale,
      isTermsAccepted: selectTermsAccepted(state),
      isTrial: false,
      paymentMethod: "INVOICE",
    };
    try {
      const result: AxiosResponse<unknown> = await axios.post(
        SUBSCRIPTION_ENDPOINT,
        subscriptionArgs
      );
      return result.data;
    } catch (e) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  }
);

export const emailValidator = (email: string): boolean => {
  return validateEmail(email);
};
export const organizationNumberValidator = (
  orgNumber: CheckoutState["organizationNumber"],
  country: CheckoutState["countryCode"]
): boolean => {
  return validateOrgNumber(orgNumber, country);
};

export const postalCodeValidator = (
  postalCode: CheckoutState["postalCode"],
  country: CheckoutState["countryCode"]
): boolean => {
  if (postalCode === null || postalCode.trim().length === 0) {
    return true;
  }
  return validatePostalCode(postalCode, country);
};
export const phoneNumberValidator = (
  phone: CheckoutState["phone"]
): boolean => {
  if (phone === null || phone.trim().length === 0) {
    return true;
  }
  return validatePhoneNumber(phone);
};

export const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    setPortfolio(state, action: PayloadAction<Portfolio>) {
      state.portfolio = action.payload;
    },
    setBillingPeriod(state, action: PayloadAction<BillingPeriod>) {
      state.billingPeriod = action.payload;
    },
    setOrganizationNumber(state, action: PayloadAction<string>) {
      state.organizationNumber = action.payload;
    },
    setOrganizationName(state, action: PayloadAction<string>) {
      state.organizationName = action.payload;
    },
    setInvoiceEmail(state, action: PayloadAction<string>) {
      state.invoiceEmail = action.payload;
    },
    setName(state, action: PayloadAction<string>) {
      state.name = action.payload;
    },
    setAddress(state, action: PayloadAction<string[]>) {
      state.address = action.payload;
    },
    setPostalCode(state, action: PayloadAction<string>) {
      state.postalCode = action.payload;
    },
    setCity(state, action: PayloadAction<string>) {
      state.city = action.payload;
    },
    setCountryCode(state, action: PayloadAction<CountryCode>) {
      state.countryCode = action.payload;
      if (action.payload && state.phone === null) {
        const locale = getLocaleByCountry(action.payload);
        if (locale) {
          state.phonePlaceHolder = locale.phonePrefix;
        }
      }
    },
    setPhone(state, action: PayloadAction<string>) {
      state.phone = action.payload;
    },
    setTermsAccepted(state, action: PayloadAction<boolean>) {
      state.isTermsAccepted = action.payload;
    },
    setRedirectAfterCheckoutTo(state, action: PayloadAction<string>) {
      const urlString = action.payload;
      try {
        const url = new URL(urlString);
        localStorage.setItem(REDIRECT_AFTER_CHECKOUT_TO_KEY, url.href);
        state.redirectAfterCheckoutTo = url.href;
      } catch (e) {
        console.warn(`passed url string: ${urlString} is not correct`);
      }
    },
    removeRedirectAfterCheckoutTo(state) {
      removeRedirectAfterCheckoutToFromStorage();
      state.redirectAfterCheckoutTo = null;
    },
    setIsEligibleForSubscription(state, action: PayloadAction<boolean>) {
      state.isEligibleForSubscription = action.payload;
    },
  },
  extraReducers: {
    [createSubscription.pending.toString()]: (state) => {
      state.createSubscriptionStatus = RequestStatus.PENDING;
    },
    [createSubscription.fulfilled.toString()]: (state) => {
      state.createSubscriptionStatus = RequestStatus.SUCCESS;
    },
    [createSubscription.rejected.toString()]: (state) => {
      state.createSubscriptionStatus = RequestStatus.FAILURE;
    },
    [getActiveSubscriptionsByPortfolioId.fulfilled.toString()]: (
      state,
      action
    ) => {
      state.isEligibleForSubscription = action.payload.length === 0;
    },
  },
});

export const {
  setPortfolio,
  setBillingPeriod,
  setOrganizationNumber,
  setOrganizationName,
  setInvoiceEmail,
  setName,
  setAddress,
  setPostalCode,
  setCity,
  setCountryCode,
  setPhone,
  setTermsAccepted,
  setRedirectAfterCheckoutTo,
  removeRedirectAfterCheckoutTo,
  setIsEligibleForSubscription,
} = checkoutSlice.actions;

export const selectPortfolio = (state: RootState) => state.checkout.portfolio;
export const selectIsPortfolioSet = (state: RootState) =>
  state.checkout.portfolio !== null;
export const selectBillingPeriod = (state: RootState) =>
  state.checkout.billingPeriod;
export const selectOrganizationNumber = (state: RootState) =>
  state.checkout.organizationNumber;
export const selectOrganizationName = (state: RootState) =>
  state.checkout.organizationName;
export const selectInvoiceEmail = (state: RootState) =>
  state.checkout.invoiceEmail;
export const selectName = (state: RootState) => state.checkout.name;
export const selectAddress = (state: RootState) => state.checkout.address;
export const selectPostalCode = (state: RootState) => state.checkout.postalCode;
export const selectTermsAccepted = (state: RootState) =>
  state.checkout.isTermsAccepted;
export const selectCity = (state: RootState) => state.checkout.city;
export const selectCountryCode = (state: RootState) =>
  state.checkout.countryCode;
export const selectPhone = (state: RootState) => state.checkout.phone;
export const selectPhonePlaceholder = (state: RootState) =>
  state.checkout.phonePlaceHolder;
export const selectCreateSubscriptionFailed = (state: RootState) =>
  state.checkout.createSubscriptionStatus === RequestStatus.FAILURE;
export const selectCreateSubscriptionSucceeded = (state: RootState) =>
  state.checkout.createSubscriptionStatus === RequestStatus.SUCCESS;
export const selectRedirectAfterCheckoutTo = (state: RootState) =>
  state.checkout.redirectAfterCheckoutTo;
export const selectIsTrial = (state: RootState) => !!state.checkout.isTrial;
export const selectIsEligibleForSubscription = (state: RootState) =>
  state.checkout.isEligibleForSubscription;
export default checkoutSlice.reducer;
