import i18next from "i18next";
import enGB from "./translations/en-GB/main.json";
import nbNO from "./translations/nb-NO/main.json";
import daDK from "./translations/da-DK/main.json";
import svSE from "./translations/sv-SE/main.json";

import { ENGLAND_LOCALE, getUserInitialLocale } from "../domain/Country";

export const initI18n = () => {
  const locale = getUserInitialLocale();
  i18next.init({
    fallbackLng: ENGLAND_LOCALE.locale,
    lng: locale.locale,
    resources: {
      "en-GB": { main: enGB },
      "nb-NO": { main: nbNO },
      "da-DK": { main: daDK },
      "sv-SE": { main: svSE },
    },
    defaultNS: "main",
  });
  return i18next;
};
