import { RootState, Store } from "./store";
import { delay } from "../utils/delay";
import {
  createSubscription,
  emailValidator,
  organizationNumberValidator,
  phoneNumberValidator,
  postalCodeValidator,
  removeRedirectAfterCheckoutTo,
  selectAddress,
  selectCity,
  selectCountryCode,
  selectCreateSubscriptionSucceeded,
  selectInvoiceEmail,
  selectIsEligibleForSubscription,
  selectOrganizationName,
  selectOrganizationNumber,
  selectPhone,
  selectPortfolio,
  selectPostalCode,
  selectRedirectAfterCheckoutTo,
  selectTermsAccepted,
} from "./CheckoutSlice";
import { redirectExternally } from "./RedirectSlice";
import { selectFlag } from "./LauchDarklySlice";
import { Flags } from "../domain/flags";

export class CheckoutHandler {
  static canCreateSubscription(state: RootState): boolean {
    const orgNumber = selectOrganizationNumber(state);
    const countryCode = selectCountryCode(state);
    const phoneNumber = selectPhone(state);
    const email = selectInvoiceEmail(state);
    const postalCode = selectPostalCode(state);
    const termsAccepted = selectTermsAccepted(state);
    const isEligibleForSubscription = selectIsEligibleForSubscription(state);
    const ADMIN_ENABLE_MULTIPLE_SUBSCRIPTIONS = selectFlag(
      Flags.ADMIN_ENABLE_MULTIPLE_SUBSCRIPTIONS
    );
    const requiredFieldsSelectors = [
      () => termsAccepted,
      selectPortfolio,
      selectOrganizationName,
      selectAddress,
      selectCity,
    ];
    const validatorResults = [
      emailValidator(email),
      organizationNumberValidator(orgNumber, countryCode),
      phoneNumberValidator(phoneNumber),
      postalCodeValidator(postalCode, countryCode),
      termsAccepted,
      isEligibleForSubscription || ADMIN_ENABLE_MULTIPLE_SUBSCRIPTIONS,
      ...requiredFieldsSelectors.map((selector) => !!selector(state)),
    ];

    return validatorResults.every((x) => x);
  }

  static async createSubscription(store: Store) {
    const state = store.getState();
    if (CheckoutHandler.canCreateSubscription(state)) {
      await store.dispatch(createSubscription());
      const redirectAfterCheckoutTo = selectRedirectAfterCheckoutTo(
        store.getState()
      );
      const createSubscriptionSucceeded = selectCreateSubscriptionSucceeded(
        store.getState()
      );
      if (redirectAfterCheckoutTo && createSubscriptionSucceeded) {
        const redirectDelayTimeSec = 3;
        delay(redirectDelayTimeSec * 1000).then(() => {
          store.dispatch(removeRedirectAfterCheckoutTo());
          store.dispatch(redirectExternally(redirectAfterCheckoutTo));
        });
      }
    } else {
      throw Error("Cannot create subscription, prerequisite validation failed");
    }
  }
}
