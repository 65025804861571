import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Toast from "@mercell/toast-react";
import mailOpened from "../../assets/images/mail_opened.png";
import {
  refreshTokens,
  removeRedirectEmailVerifiedTo,
  selectRedirectEmailVerifiedTo,
  selectRefreshTokensStatus,
  selectUser,
} from "../../redux/UserSlice";
import { redirectInternally } from "../../redux/RedirectSlice";
import { RequestStatus } from "../../redux/common";
import { USER_VERIFY_EMAIL_URL } from "../../config/constants";

function EmailVerified() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectUser);
  const redirectAfterEmailVerified = useSelector(selectRedirectEmailVerifiedTo);
  const refreshTokensStatus = useSelector(selectRefreshTokensStatus);
  useEffect(() => {
    (async () => {
      try {
        if (!user?.emailVerified) {
          if (refreshTokensStatus === RequestStatus.NOT_INITIATED) {
            await dispatch(refreshTokens());
          } else if (refreshTokensStatus === RequestStatus.SUCCESS) {
            dispatch(redirectInternally(USER_VERIFY_EMAIL_URL, history));
          }
        } else if (redirectAfterEmailVerified !== null) {
          dispatch(removeRedirectEmailVerifiedTo());
          dispatch(redirectInternally(redirectAfterEmailVerified, history));
        }
      } catch (error) {
        console.error(`Email verification failed ${error}`);
      }
    })();
  }, [
    user,
    dispatch,
    history,
    redirectAfterEmailVerified,
    refreshTokensStatus,
  ]);

  const getComponent = () => {
    switch (refreshTokensStatus) {
      case RequestStatus.SUCCESS: {
        return (
          <main className="mt-24 w-full flex flex-grow items-center justify-center">
            <div className="flex flex-col sm:flex-row space-x-6 mt-16 items-center justify-center w-full lg:w-1/2 mx-6">
              <div className="flex sm:w-1/2 h-fit-content lg:justify-end">
                <img
                  src={mailOpened}
                  alt={t("auth.email_verified.image_alt")}
                  height={174}
                  width={235}
                />
              </div>
              <div className="sm:w-1/2 mt-2">
                <h1 className="flex items-center">
                  {t("auth.email_verified.title")}
                </h1>
                <div>{t("auth.email_verified.email_has_been_confirmed")}</div>
              </div>
            </div>
          </main>
        );
      }
      case RequestStatus.FAILURE: {
        return (
          <Toast isInteractive>
            {t("auth.email_verified.email_verification_failed")}
          </Toast>
        );
      }
      default:
        return <></>;
    }
  };
  const component = getComponent();
  return component;
}

export default EmailVerified;
