import { z } from "zod";

export interface Action {
  domainId: number;
  domain: string;
  actionId: number;
  actionName: string;
}

export interface Bundle {
  id: number;
  name: string;
  actions: Action[];
}

export enum Currency {
  NOK = "NOK",
}

export const currencySuffix = (currency: Currency) => {
  switch (currency) {
    case Currency.NOK:
      return ",-";
    default:
      return "";
  }
};

export const ActionSchema = z.object({
  domainId: z.number(),
  domain: z.string(),
  actionId: z.number(),
  actionName: z.string(),
});

export const BundleSchema = z.object({
  id: z.number(),
  name: z.string(),
  actions: z.array(z.object(ActionSchema.shape)),
});

export const PortfolioSchema = z.object({
  id: z.number(),
  name: z.string(),
  priceYear: z.number(),
  priceMonth: z.number(),
  currency: z.nativeEnum(Currency),
  trialDays: z.number(),
  bundles: z.array(z.object(BundleSchema.shape)),
  serviceUrl: z.optional(z.string()),
});

export interface Portfolio {
  id: number;
  name: string;
  priceYear: number;
  priceMonth: number;
  currency: Currency;
  trialDays: number;
  bundles: Bundle[];
  serviceUrl?: string;
}

export enum BillingPeriod {
  YEARLY = "YEARLY",
  MONTHLY = "MONTHLY",
}

export const monthsInPeriod = (billingPeriod: BillingPeriod) => {
  switch (billingPeriod) {
    case BillingPeriod.YEARLY:
      return 12;
    case BillingPeriod.MONTHLY:
      return 1;
  }
};

export const stringToBillingPeriod = (value: string) => {
  const x = value as keyof typeof BillingPeriod;
  return BillingPeriod[x];
};
