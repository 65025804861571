import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import { Subscription } from "../../domain/Subscription";
import { SUBSCRIPTION_ENDPOINT } from "../../config/constants";
import { RootState } from "../store";
import { fetcher } from "../../api/fetcher";

export interface MySubscriptionsState {
  subscriptions: Subscription[];
  isSubscriptionLoading: boolean;
  isSubscriptionError: SerializedError | null;
}

const initialState: MySubscriptionsState = {
  subscriptions: [],
  isSubscriptionLoading: true,
  isSubscriptionError: null,
};

export const getActiveSubscriptions = createAsyncThunk(
  "get/mySubscriptions",
  async () => {
    return fetcher(`${SUBSCRIPTION_ENDPOINT}?isCancelled=false`);
  }
);

export const mySubscriptionsSlice = createSlice({
  name: "mySubscriptions",
  initialState,
  reducers: {
    setSubscriptions(state, action: PayloadAction<Subscription[]>) {
      state.subscriptions = action.payload;
    },
  },
  extraReducers: {
    [getActiveSubscriptions.fulfilled.toString()]: (state, action) => {
      state.subscriptions = action.payload;
      state.isSubscriptionLoading = false;
    },
    [getActiveSubscriptions.rejected.toString()]: (state, action) => {
      state.isSubscriptionLoading = false;
      state.isSubscriptionError = action.error;
    },
    [getActiveSubscriptions.pending.toString()]: (state) => {
      state.isSubscriptionLoading = true;
    },
  },
});

export const { setSubscriptions } = mySubscriptionsSlice.actions;

export const selectSubscriptions = (state: RootState) =>
  state.mySubscriptions.subscriptions;
export const selectIsSubscriptionLoading = (state: RootState) =>
  state.mySubscriptions.isSubscriptionLoading;
export const selectSubscriptionError = (state: RootState) =>
  state.mySubscriptions.isSubscriptionError;

export default mySubscriptionsSlice.reducer;
