import * as LDClient from "launchdarkly-js-client-sdk";
import { Store } from "redux";
import { changeFlags, initFlags } from "../redux/LauchDarklySlice";
import {
  FlagsChangeData,
  FlagsData,
  Flags,
  Change,
  defaultFlagData,
} from "../domain/flags";
import { observeStoreWithInitState } from "../utils/store";
import { selectUser } from "../redux/UserSlice";
import { User } from "../domain/User";
import { RootState } from "../redux/store";
import { PartialRecord } from "../types/PartialRecord";

export const READY_EVENT = "ready";
export const CHANGE_EVENT = "change";

export const toLDUser = (user: User | null) => ({
  key: user?.id ?? "anon",
  firstName: user?.name ?? "anon",
  email: user?.email ?? "anon",
});

const defaultKeys = Object.keys(defaultFlagData);
const isFlag = (k: string): k is Flags => defaultKeys.includes(k);
const isBoolean = (v: unknown): v is boolean => typeof v === "boolean";
const isChange = (v: unknown): v is Change => {
  const vRec = v as Record<string, unknown>;
  return (
    typeof vRec.current === "boolean" // && typeof vRec.previous === "boolean"
  );
};
const hasAnyValidKeys = (keys: string[]) =>
  keys.some((k) => defaultKeys.includes(k)) || keys.length === 0;

export const MSGS_ALL_FLAGS_NO_VALID_KEYS = (data: Record<string, unknown>) => [
  "Invalid payload, no recognized flags found for allFlags. Default values will be used for all flags.",
  `Received payload: ${JSON.stringify(data)}`,
];
export const MSG_FLAG_NOT_RECOGNIZED = (k: string) =>
  `Flag ${k} is not a recognized flag and will be ignored`;

export const parseFlagsData = (
  data: PartialRecord<Flags, unknown>
): [string[], FlagsData] => {
  const keys = Object.keys(data);
  if (!hasAnyValidKeys(keys)) {
    return [MSGS_ALL_FLAGS_NO_VALID_KEYS(data), defaultFlagData];
  }
  const messages: string[] = [];

  const result = Object.entries(data).reduce(
    (acc: PartialRecord<Flags, boolean>, [k]) => {
      const key = k as keyof typeof data;
      const newValue = data[key];
      if (isFlag(key) && isBoolean(newValue)) {
        acc[key] = newValue;
        return acc;
      }

      if (!isFlag(key)) {
        messages.push(MSG_FLAG_NOT_RECOGNIZED(k));
      } else {
        messages.push(
          `Flag: ${k} has an invalid value: ${newValue}. Falling back to default value for this flag.`
        );
      }

      return acc;
    },
    { ...defaultFlagData }
  );

  return [messages, result];
};

export const MSGS_CHANGE_NO_VALID_FLAGS = (data: Record<string, unknown>) => [
  "Invalid payload, no known flags for change found. No changes will be propagated.",
  `Received payload: ${JSON.stringify(data)}`,
];
export const MSG_CHANGE_INVALID_PAYLOAD = (k: string, v: unknown) =>
  `Flag: ${k} has an invalid change payload: ${JSON.stringify(
    v
  )}. Flag will be ignored.`;

export const parseFlagsChangeData = (
  data: PartialRecord<string, unknown>
): [string[], FlagsChangeData] => {
  const keys = Object.keys(data);
  if (!hasAnyValidKeys(keys)) {
    return [MSGS_CHANGE_NO_VALID_FLAGS(data), {} as FlagsChangeData];
  }
  const messages: string[] = [];
  const result = keys.reduce((acc: Record<string, Change>, k) => {
    const newValue = data[k];
    if (isFlag(k) && isChange(newValue)) {
      acc[k] = newValue;
      return acc;
    }
    if (!isFlag(k)) {
      messages.push(MSG_FLAG_NOT_RECOGNIZED(k));
    } else {
      messages.push(MSG_CHANGE_INVALID_PAYLOAD(k, newValue));
    }
    return acc;
  }, {});

  return [messages, result as FlagsChangeData];
};

export const startLaunchDarkly = (
  envKey: string,
  store: Store,
  flags: Flags[]
) => {
  const initialUser = selectUser(store.getState() as RootState);
  const ldClient = LDClient.initialize(envKey, toLDUser(initialUser));
  const onReady = () => {
    const relevantFlags = flags.reduce(
      (acc: PartialRecord<Flags, unknown>, k: Flags) => {
        acc[k] = ldClient.variation(k);
        return acc;
      },
      {}
    );
    const [messages, flagsData] = parseFlagsData(relevantFlags);
    if (messages.length > 0) {
      console.warn(
        "Mismatch in payload for allFlags and expected flags. Details:",
        messages
      );
    }
    store.dispatch(initFlags(flagsData));
  };

  const onChange = (data: FlagsChangeData) => {
    const [messages, changes] = parseFlagsChangeData(data);
    if (messages.length > 0) {
      console.warn(
        "Mismatch in payload for flag changes and expected flags. Details:",
        messages
      );
    }
    store.dispatch(changeFlags(changes));
  };

  const onUserChange = (newUser: User | null) =>
    ldClient.identify(toLDUser(newUser));

  ldClient.on(READY_EVENT, onReady);
  ldClient.on(CHANGE_EVENT, onChange);
  const unsubscribeStore = observeStoreWithInitState(
    store,
    selectUser,
    onUserChange,
    initialUser
  );

  return () => {
    unsubscribeStore();
    ldClient.off(CHANGE_EVENT, onChange);
    ldClient.off(READY_EVENT, onReady);
  };
};
