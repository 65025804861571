import { PartialRecord } from "../types/PartialRecord";

export enum Flags {
  HIDE_INVOICE_DUE_DATE = "HIDE_INVOICE_DUE_DATE",
  ADMIN_ENABLE_MULTIPLE_SUBSCRIPTIONS = "ADMIN_ENABLE_MULTIPLE_SUBSCRIPTIONS",
}

export type FlagsData = PartialRecord<Flags, boolean>;

export type Change = {
  current: boolean;
  previous: boolean;
};
export type FlagsChangeData = PartialRecord<Flags, Change>;

export const defaultFlagData = Object.values(Flags).reduce(
  (acc: PartialRecord<string, unknown>, v) => {
    acc[v] = false;
    return acc;
  },
  {}
) as FlagsData;
