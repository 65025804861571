import * as z from "zod";
import { validate } from "../redux/common";

export const UserSchema = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string(),
  emailVerified: z.boolean(),
});

export type User = z.infer<typeof UserSchema>;

const USER_KEY = "user_info";
export const getUserFromStorage = () => localStorage.getItem(USER_KEY);

export const getUser = (): User | null => {
  const stringData = getUserFromStorage();
  if (stringData) {
    const data = JSON.parse(stringData);
    try {
      return validate(data, UserSchema);
    } catch (Error) {
      removeUserFromStorage();
    }
  }
  return null;
};
export const removeUserFromStorage = () => localStorage.removeItem(USER_KEY);
export const setUserToStorage = (user: User) =>
  localStorage.setItem(USER_KEY, JSON.stringify(user));

export const REDIRECT_EMAIL_VERIFIED_TO = "redirectEmailVerifiedTo";

export const getRedirectEmailVerifiedToFromStorage = () =>
  localStorage.getItem(REDIRECT_EMAIL_VERIFIED_TO);

export const removeRedirectEmailVerifiedToFromStorage = () =>
  localStorage.removeItem(REDIRECT_EMAIL_VERIFIED_TO);

export const setRedirectEmailVerifiedToToStorage = (url: string) => {
  localStorage.setItem(REDIRECT_EMAIL_VERIFIED_TO, url);
};
