import * as z from "zod";
import { BillingPeriod, PortfolioSchema } from "./Portfolio";

export enum PaymentMethod {
  INVOICE = "INVOICE",
  CARD = "CARD",
}

export enum SubscriptionPeriodType {
  TRIAL = "TRIAL",
  SUBSCRIPTION = "SUBSCRIPTION",
}

export const SubscriptionPeriodSchema = z.object({
  type: z.nativeEnum(SubscriptionPeriodType),
  startAt: z.string(),
  finishAt: z.string(),
});

export const SubscriptionSchema = z.object({
  uuid: z.string(),
  portfolio: z.object(PortfolioSchema.shape),
  billingPeriod: z.nativeEnum(BillingPeriod),
  paymentMethod: z.nativeEnum(PaymentMethod),
  subscriptionPeriods: z.array(z.object(SubscriptionPeriodSchema.shape)),
});

export type Subscription = z.infer<typeof SubscriptionSchema>;

export type SubscriptionPeriod = z.infer<typeof SubscriptionPeriodSchema>;

export const REDIRECT_AFTER_CHECKOUT_TO_KEY = "redirectAfterCheckoutTo";

export const getRedirectAfterCheckoutToFromStorage = () =>
  localStorage.getItem(REDIRECT_AFTER_CHECKOUT_TO_KEY);

export const removeRedirectAfterCheckoutToFromStorage = () =>
  localStorage.removeItem(REDIRECT_AFTER_CHECKOUT_TO_KEY);

export const setRedirectAfterCheckoutToToStorage = (url: URL) => {
  localStorage.setItem(REDIRECT_AFTER_CHECKOUT_TO_KEY, url.href);
};
